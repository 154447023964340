function App() {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-3xl font-bold text-center">Welcome!</h1>
        <p className="mt-4 text-gray-700 text-center">
          This is my personal webpage built with React and Tailwind CSS.
        </p>
        <p className="mt-4 text-gray-700 text-center">
          This site is hosted on AWS EC2. More updates will be added soon.
        </p>
      </div>
    </div>
  );
}

export default App;
